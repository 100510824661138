<template>
  <b-modal v-model="showModal" ref="modalSelectProduct" centered @hidden="hide">
    <template #modal-header>Please Select Product(s)</template>
    <b-row>
      <b-col>
        <InputSelect
          title="Level Category 1"
          name="type"
          isRequired
          class="mt-2"
          v-model="filter.ah1"
          v-bind:options="optionsCategory.list_ah1"
          valueField="ah_id"
          textField="ah_name"
          @onDataChange="selectCategory($event, 1)"
        >
          <template v-slot:option-first>
            <b-form-select-option value="" disabled
              >-- Select Category --</b-form-select-option
            >
            <b-form-select-option :value="null">All</b-form-select-option>
          </template>
        </InputSelect>
      </b-col>
      <b-col>
        <InputSelect
          title="Level Category 2"
          name="type"
          isRequired
          class="mt-2"
          v-model="filter.ah2"
          v-bind:options="optionsCategory.list_ah2"
          valueField="ah_id"
          textField="ah_name"
          @onDataChange="selectCategory($event, 2)"
        >
          <template v-slot:option-first>
            <b-form-select-option value="" disabled
              >-- Select Category --
            </b-form-select-option>
            <b-form-select-option :value="null">All</b-form-select-option>
          </template>
        </InputSelect>
      </b-col>
      <b-col>
        <InputSelect
          title="Level Category 3"
          name="type"
          isRequired
          class="mt-2"
          v-model="filter.ah3"
          v-bind:options="optionsCategory.list_ah3"
          valueField="ah_id"
          textField="ah_name"
          @onDataChange="selectCategory($event, 3)"
        >
          <template v-slot:option-first>
            <b-form-select-option value="" disabled
              >-- Select Category --</b-form-select-option
            >
            <b-form-select-option :value="null">All </b-form-select-option>
          </template>
        </InputSelect>
      </b-col>
      <b-col>
        <InputSelect
          title="Level Category 4"
          name="type"
          isRequired
          class="mt-2"
          v-model="filter.ah4"
          v-bind:options="optionsCategory.list_ah4"
          valueField="ah_id"
          textField="ah_name"
          @onDataChange="selectCategory($event, 4)"
        >
          <template v-slot:option-first>
            <b-form-select-option value="" disabled
              >-- Select Category --</b-form-select-option
            >
            <b-form-select-option :value="null">All</b-form-select-option>
          </template>
        </InputSelect>
      </b-col>
    </b-row>
    <b-input-group class="mt-2">
      <b-form-input
        placeholder="Search Product Name"
        v-model.trim="filter.search"
        @keyup.enter="handleSearch"
        v-debounce:500ms="handleSearch"
      ></b-form-input>
      <b-input-group-append>
        <b-input-group-text>
          <b-iconstack
            font-scale="2"
            type="submit"
            @click.prevent="handleSearch"
            v-debounce:500ms="handleSearch"
          >
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
    <b-table
      striped
      hover
      responsive
      :items="productLists"
      :fields="fields"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      class="mt-2"
    >
      <template v-slot:cell(name)="data">
        <div class="text-left">
          <p class="m-0 text-black" v-if="data.item.name">
            {{ data.item.name }}
          </p>
          <div>
            {{ data.item.article_no }}
            <span class="article-type">{{ data.item.article_type }}</span>
          </div>
        </div>
      </template>
      <template v-slot:cell(imge_url)="data">
        <div class="position-relative picture-text pic-table">
          <div
            v-if="data.item.imageUrl"
            class="pic-box"
            v-bind:style="{
              'background-image': 'url(' + data.item.imageUrl + ')',
            }"
          ></div>
          <div v-else>
            <img
              class="pic-box pt-0"
              :src="default_image"
              alt="default Image"
              srcset=""
            />
          </div>
        </div>
      </template>
      <template v-slot:cell(price)="data">
        <p class="m-0 price-text" v-if="data.item.price">
          {{ data.item.price | numeral("0,0.00") }}
        </p>
        <p v-else class="m-0 price-text">-</p>
      </template>
      <template v-slot:cell(id)="data">
        <div>
          <b-form-checkbox-group v-model="selected">
            <b-form-checkbox
              :key="data.item.id"
              :value="data.item.id"
              @change="getChk($event, data.item)"
              :disabled="isDisabled(data)"
            >
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="form-inline justify-content-center justify-content-sm-start mb-2 mb-sm-0"
      >
        <p class="mb-0 p-gray">
          Showing {{ showing === 1 ? showing : showing + 1 }} to
          {{ showingTo }} of {{ rows | numeral("0,0") }} entries
        </p>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="form-inline justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="filter.pageNo"
          :total-rows="rows"
          :per-page="filter.length"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          class="m-md-0"
          @change="pagination"
        ></b-pagination>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click.prevent="hide(1)" class="border-btn"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="submit-btn" @click.prevent="submit">Save</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    InputSelect,
  },
  props: {
    valid: {
      required: false,
      type: Number,
    },
    isLoading: {
      required: false,
      type: Boolean,
    },
    useBy: {
      required: true,
    },
    freezeProductList: {
      required: false,
      type: Array,
      default: () => [],
    },
    isSap: {
      required: false,
      default: false,
    },
    isAlreadyUse: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        { key: "id", label: "" },
        { key: "imge_url", label: "Image", thClass: "table-width" },
        { key: "name", label: "Name / Description" },
        { key: "ah_no", label: "SKU" },
        { key: "price", label: "Price" },
      ],
      optionsCategory: {
        list_ah1: [],
        list_ah2: [],
        list_ah3: [],
        list_ah4: [],
      },

      showModal: false,
      selected1: "",
      selected2: "",
      selected3: "",
      selected4: "",
      selected: [],
      isBusy: false,
      showing: 1,
      showingTo: 5,
      productLists: [],

      rows: 0,
      filter: {
        search: "",
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: "",
        ah2: "",
        ah3: "",
        ah4: "",
        promotion_id: this.$route.params.id,
        add_product_id: [],
        delete_product_id: [],
      },
    };
  },
  async mounted() {
    // if (this.productLists?.length > 0) {
    //   await this.checkedSelect();
    // }
  },
  watch: {
    // productLists: function () {
    //   if (this.productLists?.length > 0) {
    //     this.productSelect = this.selected;
    //     this.checkedSelect();
    //   }
    // },
  },
  methods: {
    async show(product_list) {
      if (!this.isSap) {
        let product = { ...product_list };

        if (!product.delete_product_id) product.delete_product_id = [];
        if (!product.add_product_id) product.add_product_id = [];
        product.product_id = product.product_id.filter(
          (id) => !product.delete_product_id.includes(id)
        );
        this.selected = [...product.product_id, ...product.add_product_id];
      } else {
        let product = [...product_list];

        this.selected = product.map(({ product_id }) => product_id);
      }
      this.showModal = true;
      this.getProductList();
    },

    hide(status) {
      this.filter.ah1 = "";
      this.filter.ah2 = "";
      this.filter.ah3 = "";
      this.filter.ah4 = "";
      this.showModal = false;
    },
    handleSearch(val) {
      this.getProductList();
    },
    pagination(value) {
      this.filter.pageNo = value;
      this.filter.start = value * this.filter.length - this.filter.length;
      this.getProductList();
    },
    getChk(checked, item) {},
    checkedSelect() {},
    isDisabled(data) {
  
      if (this.isSap) {
        return (
          this.freezeProductList.filter((el) => el.product_id == data.item.id)
            .length >= 1
        );
      } else {
        return (
          this.freezeProductList.filter((el) => el == data.item.id).length >=
            1 && this.isAlreadyUse
        );
      }
    },
    async getProductList() {
      this.isBusy = true;
      var data;

      const url = this.isSap
        ? `${process.env.VUE_APP_API}/promotion/GetSapPromotionProductListByCategory`
        : `${process.env.VUE_APP_API}/promotion/GetPromotionProductListByCategory`;
      if (this.useBy == "promotions") {
        const { data } = await this.$Axios.post(url, this.filter);
        this.rows = data.detail.total_product;
        this.productLists = data.detail.product_list;
        this.optionsCategory = {
          list_ah1: data.detail.list_ah1,
          list_ah2: data.detail.list_ah2,
          list_ah3: data.detail.list_ah3,
          list_ah4: data.detail.list_ah4,
        };
      } else {
        await this.$store.dispatch("getListProduct", this.filter);
        data = this.$store.state.redeem.productList;
        this.productLists = data.detail.product_list;
        this.rows = data.detail.total_product;

        this.optionsCategory = {
          list_ah1: data.detail.list_ah1,
          list_ah2: data.detail.list_ah2,
          list_ah3: data.detail.list_ah3,
          list_ah4: data.detail.list_ah4,
        };
      }

      this.isBusy = false;
    },
    submit() {
      this.hide();
      this.$emit("submit", this.selected);
    },
    selectCategory(value, no) {
      this.getProductList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--secondary-color);
  color: var(--font-color);
  font-weight: 600;
}
::v-deep .modal-dialog {
  max-width: 800px;
}
::v-deep .input-group-text {
  padding: 0;
  background-color: transparent;
}
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
  text-align: center;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.pic-box {
  margin: auto !important;
  width: 50%;
  padding-top: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
::v-deep .table-width {
  width: 150px;
}
</style>
